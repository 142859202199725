import { Autocomplete, AutocompleteProps, Icon, TextFieldProps } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { isNil } from "lodash";
import React, { useState } from "react";

interface Props extends Omit<AutocompleteProps, "textField" | "selected" | "onSelect"> {
  selected?: string[];
  textFieldProps?: Partial<TextFieldProps>;
  onSelect?(value: string[]): void;
  onChange?(value: string): void;
  options: any;
}

//TODO change options type;

export function AutoCompleteWithTextField({
  options,
  selected,
  onSelect,
  onChange,
  textFieldProps,
  ...props
}: Props): JSX.Element {
  const [searchText, setSearchText] = useState("");

  const autocompleteTextfield = (
    <Autocomplete.TextField
      autoComplete="on"
      {...textFieldProps}
      onChange={value => {
        setSearchText(value);
        onChange?.(value);
      }}
      label=""
      value={isNil(textFieldProps?.value) ? searchText : textFieldProps?.value}
      prefix={<Icon source={SearchMinor} color="highlight" />}
      placeholder={textFieldProps?.placeholder || "Search..."}
    />
  );

  const autocompleteSelectedOptions =
    selected === undefined
      ? options
          .filter((option: { value: string }) => option.value.toLowerCase().includes(searchText.toLowerCase()))
          .map((o: { value: any }) => o.value)
      : selected;

  const onSelectValue = (value: string[]) => {
    const selectedOption = options.find((o: { value: string }) => o.value === value[0]);
    setSearchText(selectedOption?.email?.toString() || "");
    onSelect?.(value);
  };

  return (
    <Autocomplete
      {...props}
      options={options}
      textField={autocompleteTextfield}
      onSelect={onSelectValue}
      selected={autocompleteSelectedOptions}
    />
  );
}
